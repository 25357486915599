define("discourse/plugins/swapd-topic-promo/discourse/initializers/topic-promo", ["exports", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/lib/ajax", "discourse-common/lib/icon-library"], function (_exports, _pluginApi, _composer, _ajax, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    if (!siteSettings.topic_promo_enabled) {
      return;
    }
    api.modifyClass("component:topic-list-item", {
      pluginId: "swapd-topic-promo",
      classNameBindings: ["topic.topic_promoted", "topic.topic_promoted_in_group", "topic.closed"]
    });
    const currentUser = api.getCurrentUser();
    if (!currentUser || !currentUser.admin) {
      return;
    }
    api.addTopicAdminMenuButton(topic => {
      const highlighted = topic.topic_highlighted;
      return {
        action: () => {
          (0, _ajax.ajax)(`/t/${topic.id}/highlight`, {
            type: "POST",
            data: {
              highlighted: !!!highlighted
            }
          }).then(result => {
            topic.set("topic_highlighted", result.highlighted);
          });
        },
        icon: topic.get("topic_highlighted") ? "volume-mute" : "volume-up",
        label: topic.get("topic_highlighted") ? "topic_promo.remove_highlight" : "topic_promo.highlight_topic"
      };
    });
  }
  var _default = _exports.default = {
    name: "topic-promo",
    initialize: () => {
      (0, _pluginApi.withPluginApi)("0.8.6", initWithApi);
    }
  };
});